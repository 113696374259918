
const MYPLAY_APP_PRODUCTION_URL = "https://app.myplay.com";
const MYPLAY_TAGGER_SYSTEM_URL = "https://taggers.myplay.com";
const MYPLAY_AD_ADMIN = "https://manage.myplay.com";
const LOCAL_STORAGE_AT = "MYPLAY/accessToken";
const LOCAL_STORAGE_RT = "MYPLAY/refreshToken";
const LOCAL_STORAGE_ID = "MYPLAY/userId";
const LOCAL_STORAGE_ACCOUNT = "MYPLAY/accountType";

const getUrl = () => {
  switch (process.env.REACT_APP_STAGE) {
    case "production":
      return MYPLAY_APP_PRODUCTION_URL;
    case "local":
      return "http://localhost:3000";
    case "develop":
      return "https://dev1.myplay.com";
    default: 
      return MYPLAY_APP_PRODUCTION_URL;
  }
};

const MYPLAY_APP_URL = getUrl();
const isValidUrl = str => {
  try {
    new URL(str);
  } catch (_) {
    return false;
  }
  return true;
};

const getBlacklistRoutesByAccountType = accountType => {
  switch (accountType) {
    case "player":
      return [MYPLAY_AD_ADMIN];
    case "coach":
      return [MYPLAY_AD_ADMIN];
    case "operator": 
      return [];
    case "tagger":
      return [MYPLAY_AD_ADMIN]; 
    default:
      return [MYPLAY_AD_ADMIN]
  }
}
export { 
  MYPLAY_APP_URL,
  MYPLAY_APP_PRODUCTION_URL,
  MYPLAY_TAGGER_SYSTEM_URL,
  MYPLAY_AD_ADMIN,
  LOCAL_STORAGE_ACCOUNT, 
  LOCAL_STORAGE_AT,
  LOCAL_STORAGE_RT,
  LOCAL_STORAGE_ID,
  isValidUrl, 
  getBlacklistRoutesByAccountType 
};
