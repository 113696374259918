import React from 'react';

import { MYPLAY_APP_URL } from '../../utils/constants';
import './ForgotPassword.scss';

const ForgotPassword = ()=> {
  return (
    <div className="forgot-password">
      <span>●</span>
      <a 
        className="forgot-password__link" 
        href={`${MYPLAY_APP_URL}/reset-password`}
      >
        Forgot Password?
      </a>
    </div>
  );
};

export default ForgotPassword;