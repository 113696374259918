import React, { Component } from "react";
import {
  Switch,
  Route,
  BrowserRouter as Router,
  Redirect
} from "react-router-dom";

import { LOCAL_STORAGE_AT, LOCAL_STORAGE_RT, LOCAL_STORAGE_ID, LOCAL_STORAGE_ACCOUNT } from "./utils/constants";
import Login from "./components/Login";

const Logout = () => {
  localStorage.setItem(LOCAL_STORAGE_AT, "");
  localStorage.setItem(LOCAL_STORAGE_RT, "");
  localStorage.setItem(LOCAL_STORAGE_ID, "");
  localStorage.setItem(LOCAL_STORAGE_ACCOUNT, "");
  return <Redirect to="/" />;
};

class App extends Component {
  render() {
    return (
      <Router>
        <Switch>
          <Route path="/logout">
            <Logout />
          </Route>
          <Route path="/">
            <Login />
          </Route>
        </Switch>
      </Router>
    );
  }
}

export default App;
