import React, { Component } from "react";
import { SuccessButton, DotLoader } from "@myplay/ui";
import { utils, user } from "@myplay/all";
import FacebookLogin from "react-facebook-login";
import AppleLogin from "react-apple-login";

import logo from "../../assets/logo-name.png";
import LoginForm from "../LoginForm";
import ForgotPassword from "../ForgotPassword";
import facebookIcon from "../../assets/facebook-f.svg";
import {
  getBlacklistRoutesByAccountType,
  MYPLAY_APP_URL,
  MYPLAY_APP_PRODUCTION_URL,
  MYPLAY_TAGGER_SYSTEM_URL,
  LOCAL_STORAGE_ACCOUNT,
  LOCAL_STORAGE_AT,
  LOCAL_STORAGE_ID,
  LOCAL_STORAGE_RT
} from "../../utils/constants";
import "./Login.scss";

const { isNumeric } = utils;

class Login extends Component {
  state = {
    shouldDisplayForm: false,
    password: "",
    userName: "",
    userNameError: "",
    passwordError: "",
    isLoading: false
  };

  async componentDidMount() {
    document.addEventListener("keypress", this.handleEnterPress)
    if (!window.location.search) {
      return;
    }

    const params = new URLSearchParams(window.location.search);
    this.redirectCount = Number(params.get("redirectCount") || 1);
    const redirectTo = params.get("redirect");
    localStorage.setItem("redirectTo", redirectTo || "");
    const code = params.get("code");
    if (code) {
      this.setState({ isLoading: true });
      await this.handleAppleSignin(code);
      return;
    }
    if(localStorage.getItem(LOCAL_STORAGE_AT) && localStorage.getItem(LOCAL_STORAGE_RT)) {
      if (this.redirectCount >= 5) {
        this.setTokens("", "", "");  
      } else {
        this.redirect();     
      }
    }
  } 

  componentWillUnmount() {
    document.removeEventListener("keypress", this.handleEnterPress);
  }

  handleLogin = () => {
    const { shouldDisplayForm } = this.state;

    if (shouldDisplayForm) {
      this.validateClient();
      return;
    }

    this.setState({
      shouldDisplayForm: true
    });
  };

  handlePasswordChange = password => {
    this.setState({ password });
  };

  handleUserNameChange = userName => {
    this.setState({ userName });
  };

  validateClient = () => {
    const { password, userName } = this.state;
    const isUserNameValid =
      utils.isValidEmail(userName) || utils.isValidPhone(userName);

    if (isUserNameValid && password.length >= 6) {
      this.setState({ isLoading: true })
      this.login();
    }

    this.setState({
      userNameError: isUserNameValid ? "" : "Invalid Email/Phone",
      passwordError:
        password.length >= 6 ? "" : "Password should be at least six characters"
    });
  };

  login = async () => {
    const { password, userName } = this.state;
    try {
      const res = await user.auth.login(userName, password);
      const {
        accessToken,
        refreshToken,
        userData: { _id, accountType }
      } = res;
      if (accessToken && refreshToken) {
        this.setTokens(accessToken, refreshToken, _id, accountType);
        this.redirect();
      }
    } catch (e) {    
      if(JSON.stringify(e).includes("403")) {
        this.setState({
          passwordError: "This user is blocked, Please contact us for more information",
          isLoading: false
        });
        return;
      }
      const { error } = e;
      if (error.includes("password")) {
        this.setState({
          passwordError: error,
          isLoading: false
        });
      } else {
        this.setState({
          userNameError: error,
          isLoading: false
        });
      }
    }
  };

  handleFacebookConnect = async fbAccessToken => {
    try {
      this.setState({ isLoading: true })
      const {
        accessToken,
        userData: { refreshToken, _id, status }
      } = await user.auth.loginWithFacebook(fbAccessToken);

      if (accessToken && refreshToken) {
        this.setTokens(accessToken, refreshToken, _id);
        if (status === "registerIncomplete") {
          window.location.href = `${MYPLAY_APP_PRODUCTION_URL}/register?accessToken=${accessToken}&refreshToken=${refreshToken}&userId=${_id}&facebook=true`;
        } else {
          this.redirect();
        }
      }
    } catch (error) {
      console.log(error);
      this.setState({ isLoading: false })
    }
  };

  setTokens = (accessToken, refreshToken, id, accountType) => {
    localStorage.setItem(LOCAL_STORAGE_AT, accessToken);
    localStorage.setItem(LOCAL_STORAGE_RT, refreshToken);
    localStorage.setItem(LOCAL_STORAGE_ID, id);
    localStorage.setItem(LOCAL_STORAGE_ACCOUNT, accountType);
  };

  redirect = () => {
    const AT = localStorage.getItem(LOCAL_STORAGE_AT);
    const RT = localStorage.getItem(LOCAL_STORAGE_RT);
    const userId = localStorage.getItem(LOCAL_STORAGE_ID);
    const accountType = localStorage.getItem(LOCAL_STORAGE_ACCOUNT);
    this.setRedirectBaseRoute(accountType);
    const redirectTo = localStorage.getItem("redirectTo"); 
    this.redirectCount = isNumeric(parseInt(this.redirectCount)) ? parseInt(this.redirectCount) : 1;
    const url = `${redirectTo}/?accessToken=${AT}&refreshToken=${RT}&userId=${userId}&redirectCount=${this.redirectCount++}`;
    localStorage.setItem("redirectTo", "");
    window.location.replace(url);
  }

  isRedirectRouteBlacklisted = accountType => {
    const blacklistRoutes = getBlacklistRoutesByAccountType(accountType);
    const redirectTo = localStorage.getItem("redirectTo");
    return blacklistRoutes.includes(redirectTo);
  }

  setRedirectBaseRoute = accountType => {
    const isBlocked = this.isRedirectRouteBlacklisted(accountType);
    const redirectTo = localStorage.getItem("redirectTo");
    if (redirectTo && !isBlocked) {
      return;
    };

    switch(accountType) {
      case "player":
      case "coach":
      case "operator":
        localStorage.setItem("redirectTo", MYPLAY_APP_PRODUCTION_URL);
        break;
      case "tagger":
        localStorage.setItem("redirectTo", MYPLAY_TAGGER_SYSTEM_URL);
        break; 
      default:
        localStorage.setItem("redirectTo", MYPLAY_APP_PRODUCTION_URL);
        break;
    }
  }

  handleAppleSignin = async code => {
    try {
      const {
        userData,
        accessToken,
        refreshToken,
        status
      } = await user.auth.loginWithAppleId(code);

      if (status === 201) {
        window.location.href = `${MYPLAY_APP_PRODUCTION_URL}/register?accessToken=${accessToken}&refreshToken=${refreshToken}&userId=${userData._id}&apple=true`;
        return;
      };

      this.setTokens(accessToken, refreshToken, userData._id, userData.accountType);
      this.redirect();
    } catch (error) {
      console.log(error);
      this.setState({ isLoading: false })
    }
  }

  handleEnterPress = event => {
    if (event.key === "Enter") {
      this.handleLogin();
    }
  }

  render() {
    const {
      shouldDisplayForm,
      password,
      userName,
      passwordError,
      userNameError,
      isLoading
    } = this.state;
    return (
      <div className="login">
        <img className="login__logo" src={logo} alt="myplay-logo" />
        {isLoading ?
          <div className="login__buttons-container">
            <DotLoader /> 
          </div>:
          <div className="login__buttons-container">
            <SuccessButton
              background="#16c4ab"
              style={{ width: "320px" }}
              onClick={() => (window.location.href = `${MYPLAY_APP_URL}/register`)}
            >
              <span>SIGN UP</span>
            </SuccessButton>
            <FacebookLogin
              appId="1730542720518279"
              fields="name,email,picture"
              callback={fbData => this.handleFacebookConnect(fbData.accessToken)}
              textButton="FACEBOOK CONNECT"
              cssClass="login__buttons-container__facebook-button"
              icon={
                <img
                  className="login__buttons-container__facebook-button__icon"
                  src={facebookIcon}
                />
              }
            />
            <AppleLogin
              clientId="com.myplay.web"
              redirectURI="https://id.myplay.com/login"
              responseType={"code"}
              designProp={{
                height: 42,
                width: 320,
                color: "white",
                border: false,
                type: "sign-in",
                border_radius: 15,
                scale: 1,
                locale: "en_US"
              }}
            />
            {shouldDisplayForm ? (
              <LoginForm
                onUserNameChange={this.handleUserNameChange}
                onPasswordChange={this.handlePasswordChange}
                password={password}
                userName={userName}
                passwordError={passwordError}
                userNameError={userNameError}
              />
            ) : (
              <span>OR</span>
            )}
            <SuccessButton style={{ width: "320px" }} onClick={this.handleLogin}>
              <span>Login</span>
            </SuccessButton>
            {shouldDisplayForm && <ForgotPassword />}
          </div>
        }
      </div>
    );
  }
}

export default Login;
