import React from 'react';
import { Input } from '@myplay/ui';

import './LoginForm.scss';

const LoginForm = ({ userName, password, onUserNameChange, onPasswordChange, userNameError, passwordError }) => {
  return (
    <div className="login-form">
      <Input 
        className="login-form__input" 
        label="E-mail/Phone" 
        value={userName}
        onChange={event => onUserNameChange(event.target.value)}
      />
      <span 
        style={{ 
          color: userNameError ? "#efd90a" : "transparent", 
          marginBottom: "25px" 
        }}
        className="login-form__error"
      >
        {userNameError}
      </span>
      <Input 
        className="login-form__input" 
        label="Password" 
        value={password}
        onChange={event => onPasswordChange(event.target.value)}
        type="password"
      />
      <span 
        style={{ color: passwordError ? "#efd90a" : "transparent" }}
        className="login-form__error"
      >
        {passwordError}
      </span>
    </div>
  );
};

export default LoginForm;